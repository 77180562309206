import React from "react"
import "./layout.scss";
import { Header } from './Header/header';
import { HeroStar } from "./HeroStart/heroStar"
import LeftLogo from "./../images/logos_org.png";
import RightLogo from "./../images/top_logos_2.png";
import LogoBig from "./../images/top_logos.png";
import { Helmet } from 'react-helmet';

const Layout = ({ data, location, title, children, headerStyle, pageRefs, sectionClass }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const pages = ['values', 'culture', 'remembrance', 'kontakt'];
  const isNotMainPage = pages.some(pageName => location.pathname.includes(pageName));

  return (
    <>
      <Helmet>
        <title>{`Mennonici w Polsce`}</title>
        <meta name="description" content={`Mennonici w polsce`} />
      </Helmet>
    <div className={`m-layout__wrapper ${sectionClass ? sectionClass : ''}`} data-is-root-path={isRootPath}>
      <Header headerStyle={headerStyle} anchorRefs={pageRefs}/>
      <div className={`m-layout__logos ${isNotMainPage ? 'm-layout__logos-extended' : '' }`}>
        {
          pages.some(pageName => location.pathname.includes(pageName))
            ? (
              <div className="m-logos__container--left m-logos__container" style={{ backgroundImage: `url(${LogoBig})` }}></div>
          ) :
            <>
              <div className="m-logos__container--left m-logos__container" style={{ backgroundImage: `url(${LeftLogo})` }}></div>
              <div className="m-logos__container--right m-logos__container" style={{ backgroundImage: `url(${RightLogo})` }}></div>
            </>
        }

      </div>
      <main className={`m-main`}>
        {!pages.some(pageName => location.pathname.includes(pageName)) && <HeroStar />}
        {children}
      </main>
    </div>
    </>
  )
}

export default Layout
