import React, { useEffect } from "react";
import "./index.scss";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl"

export const PageMenu = ({ menuItems, closeHandler }) => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => document.body.style.overflow = "auto";
  }, [])

  return(
    <div className="m-page-menu__container">
      <div className="m-page-menu__close" onClick={closeHandler}></div>
      {
        menuItems.sort((a, b) => parseInt(a.frontmatter.order) - parseInt(b.frontmatter.order)).map(item => {
          return !item.frontmatter.downloadFile ?
             (
              <Link className="m-page-menu__item" to={`${item.frontmatter.external ? item.frontmatter.pageUrl : '/' + intl.locale + '/' + item.frontmatter.pageUrl}`} target={'_blank'}>
                <span>{item.frontmatter.title}</span>
              </Link>
            )
            :
            <a className="m-page-menu__item" download href={item.frontmatter.downloadFile}>{item.frontmatter.title}</a>
        })
      }
    </div>
  )
}
