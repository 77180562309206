import React, { useEffect, useState, createRef, useRef } from 'react';
import './index.scss';
import { useIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useWindowSize } from "../../hooks/useWindowSize"

export const SectionNavigation = React.memo(({ lang, pageRefs, closeHandler }) => {
  const menuRefs = useRef([]);
  const [activeSection, setActiveSection] = useState('');
  const intl = useIntl();
  const locale = intl.locale !== "pl" ? `${intl.locale}` : "pl";
  const isMobile = useWindowSize().width <= 768;

  useEffect(() => {
    findCurrentSection(window.scrollY)

    const handleScroll = (e) => {
      findCurrentSection(window.scrollY)
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pageNavigationData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "exhibition-nav-item"}}}) {
        nodes {
          frontmatter {
            title
            language
            content
            sectionNr
            positionNr
            menuItem
            pageName
          }
        }
      }
    }
  `)

  const sectionMenuResources = pageNavigationData.allMarkdownRemark.nodes.filter(item => item.frontmatter.language === locale);
  const menuItems = sectionMenuResources.filter(element => element.frontmatter.menuItem);
  const menuSections = menuItems.filter(el => el.frontmatter.positionNr === 0).sort((a, b) => a.frontmatter.sectionNr - b.frontmatter.sectionNr);

  const sortedMenu = menuItems.sort((a, b)=> {
    if (a.frontmatter.sectionNr === b.frontmatter.sectionNr){
      return a.frontmatter.positionNr < b.frontmatter.positionNr ? -1 : 1
    } else {
      return a.frontmatter.sectionNr < b.frontmatter.sectionNr ? -1 : 1
    }
  })

  const menuItemsArrLen = sortedMenu.filter(el => el.frontmatter.positionNr !== 0).length;
  if (menuRefs.current.length !== menuItemsArrLen) {
    menuRefs.current = Array(menuItemsArrLen).fill().map((_, i) => menuRefs.current[i] || createRef());
  }

  const findCurrentSection = (scrollTop) => {
    const activeSection = pageRefs.filter(ref => scrollTop >= ref.offset && scrollTop < ref.offset + ref.height)[0];
    activeSection && setActiveSection(activeSection.id)
  }

  return (
    <div className="m-section-navigation__container">
      {isMobile && (<div className="m-section-navigation-close m-page-menu__close" onClick={closeHandler}></div>)}
      <div className="m-section-navigation__items">
        {
          menuSections.map(el => (
              <div className="m-section-navigation__section">
                <Link className={`m-section-navigation__title`}
                      to={`/${locale}/${el.frontmatter.pageName || ''}`}
                >
                  {el.frontmatter.content}
                </Link>
                {
                  sortedMenu.filter(menuEl => menuEl.frontmatter.sectionNr === el.frontmatter.sectionNr && menuEl.frontmatter.positionNr !== 0).map((item, i) => {
                    return (
                      <Link ref={menuRefs[i]}
                            className={`m-section-navigation__subtitle ${activeSection === item.frontmatter.title ? 'm-section-navigation__subtitle--active' : ''}`}
                            to={`/${locale}/${item.frontmatter.pageName ? item.frontmatter.pageName : ''}#${item.frontmatter.title}`}
                      >
                        {item.frontmatter.content}
                        {/*{activeSection === item.frontmatter.title && (*/}
                        {/*<span className="m-section-navigation__subtitle-icon">*/}
                        {/*  <StarMenu />*/}
                        {/*</span>*/}
                        {/*)}*/}
                      </Link>
                    )
                  })
                }
              </div>
            ))
        }
      </div>
    </div>
  )
})
