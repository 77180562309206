import React from 'react';
import MainStar from "../../images/svg/star_big.svg"
import ArrowIndicator from "../../images/svg/arrow_down.svg"
import { Curve } from "../Svg/Curve"
import { StarMenu } from "../Header/StarMenu"
import { TitleDe } from "../Svg/TitleDe"
import { TitleEn } from "../Svg/TitleEn"
import { TitlePl } from "../Svg/TitlePl"
import { useIntl } from "gatsby-plugin-intl"
import './index.scss';
import { graphql, useStaticQuery } from "gatsby"

export const HeroStar = () => {
  const intl = useIntl();

  const getTitleIcon = () => {
    switch (intl.locale) {
      case "de":
        return <TitleDe />
      case "en":
        return <TitleEn />
      default:
        return <TitlePl />
    }
  }

  const heroData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "layout-item"}}}) {
        nodes {
          frontmatter {
            title
            subtitle1
            subtitle2
            language
          }
        }
      }
    }
  `)
  const heroResources = heroData.allMarkdownRemark.nodes.filter(node => node.frontmatter.language === intl.locale)[0];

  return (
      <div className="m-main__hero">
      <div className="m-main__hero-icon">
        <div className="m-main__hero-icon-svg"><MainStar /></div>
        <div className="m-main__hero-text">
          <div className="m-main__hero-title-icon">
            { getTitleIcon() }
          </div>
          <h2 className="m-main__hero-subtitle">{heroResources.frontmatter.subtitle1}</h2>
          <h2 className="m-main__hero-subtitle m-main__hero-subtitle--lower">{heroResources.frontmatter.subtitle2}</h2>
        </div>
        <div className="m-main__hero-navigation">
          <div className="m-main__hero-indicator"><ArrowIndicator /></div>
        </div>
        <div className="m-main__hero-indicator-lower">
          <div className="m-main__hero-lower-curve-left">
            <Curve />
          </div>
          <div className="m-main__hero-lower-curve-right">
            <Curve />
          </div>
          <div className="m-main__hero-lower-star">
            <StarMenu />
          </div>
        </div>
      </div>
    </div>
  )
}
