import React, { useState, useEffect } from "react"
import LanguageButton from "../../images/svg/language_button.svg"
import { LangMenu } from "../LangMenu/LangMenu"
import ContentsButton from "../../images/svg/contents_button.svg"
import { PageMenu } from "../PageMenu/PageMenu"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl";
import { StarMenu } from "./StarMenu"
import { SectionNavigation } from "../SectionNavigation/sectionNavigation"

export const Header = ({ headerStyle, anchorRefs }) => {
  const intl = useIntl();
  const locale = intl.locale !== "pl" ? `${intl.locale}` : "pl"
  const possibleLocales = ["pl", "de", "en"];
  const [langMenuOn, setLangMenu] = useState(false);
  const [mainMenuOpen, setMenuIsOpen] = useState(false);
  const [contentMenuOn, setContentMenu] = useState(false);

  const handleMainMenu = () => {
    setMenuIsOpen(false)
  }

  const menuData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "menu-item"}}}) {
        nodes {
          frontmatter {
            title
            language
            pageUrl
            external
            order
            downloadFile
          }
        }
      }
    }
  `)

  return (
    <header className="m-header__container">
        <div className="m-header__content">
          <div className="m-header__icons">
            <div className="m-language__switch"
                 id={locale}
                 onClick={() => setLangMenu(!langMenuOn)}
            >
              <div className="m-language-current"><span className="m-language-current-text">{locale}</span></div>
              <LanguageButton />
              {langMenuOn && <LangMenu languages={possibleLocales.filter(lang => lang !== locale)} />}
            </div>
            <div className="m-contents__icon m-contents-menu__button"
                 onClick={() => setContentMenu(!contentMenuOn)}
            >
              <ContentsButton />
              {contentMenuOn && <SectionNavigation lang={locale} pageRefs={anchorRefs} closeHandler={() => setContentMenu(false)} />}
            </div>
          </div>
          <div className="m-header__menu">
            <div className="m-header__menu-button" onClick={() => setMenuIsOpen(true)}>
              <StarMenu />
            </div>
          </div>
        </div>
      {mainMenuOpen && <PageMenu closeHandler={handleMainMenu} menuItems={menuData.allMarkdownRemark.nodes.filter(item => item.frontmatter.language === locale)} />}
    </header>
  )
}
