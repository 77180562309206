import * as React from "react"

export const Curve = (props) => {
  return (
    <svg
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 223.08 88.13"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a" transform="translate(-186.56 -377.6)">
          <path d="M186.56 377.61h223.08v88.13H186.56z" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M223.08 87.76h-69.14s-37.35 2.38-54-44.88S67.63.49 0 .49"
          stroke="#2c79c4"
          strokeWidth={2}
          fill="none"
        />
      </g>
    </svg>
  )
}
