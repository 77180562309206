import * as React from "react"

export const StarMenu = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85.05 85.05"
      {...props}
    >
      <style>
        {
          ".prefix__E{fill:none}.prefix__F{stroke:#2c79c4}.prefix__G{stroke-width:.94}.prefix__H{fill:#000f1c}"
        }
      </style>
      <defs>
        <path
          id="prefix__B"
          d="M36.85 36.33a9.48 9.48 0 015.68-7 9.45 9.45 0 015.67 7"
        />
        <path
          id="prefix__C"
          d="M36.85 48.71a9.46 9.46 0 005.68 7 9.43 9.43 0 005.67-7"
        />
        <path
          id="prefix__D"
          d="M36.34 48.19a9.44 9.44 0 01-7-5.67 9.46 9.46 0 017-5.68"
        />
        <path
          id="prefix__E"
          d="M48.72 48.19a9.43 9.43 0 007-5.67 9.46 9.46 0 00-7-5.68"
        />
        <clipPath id="prefix__A" transform="translate(-216.15 -420.45)">
          <path d="M216.16 420.44h85.04v85.04h-85.04z" className="prefix__E" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__A)">
        <use xlinkHref="#prefix__B" className="prefix__H" />
        <use xlinkHref="#prefix__B" className="prefix__E prefix__F prefix__G" />
        <use xlinkHref="#prefix__C" className="prefix__H" />
        <use xlinkHref="#prefix__C" className="prefix__E prefix__F prefix__G" />
        <use xlinkHref="#prefix__D" className="prefix__H" />
        <use xlinkHref="#prefix__D" className="prefix__E prefix__F prefix__G" />
        <use xlinkHref="#prefix__E" className="prefix__H" />
        <use xlinkHref="#prefix__E" className="prefix__E prefix__F prefix__G" />
        <path
          d="M63.94 51.55L51.68 63.81s7.68 6.58 16.82 4.56c2-9.14-4.56-16.82-4.56-16.82m-31.88 1.9S33 74.04 42.53 84.33c9.53-10.29 10.46-30.88 10.46-30.88zm-11-1.9s-6.57 7.68-4.56 16.82c9.15 2 16.82-4.56 16.82-4.56zm.1-18.12l12.16-12.16s-7.67-6.58-16.82-4.56c-1.92 8.73 4 16.11 4.51 16.75-6.63 1.37-14.93 4-20.37 9 10.3 9.53 30.88 10.46 30.88 10.46V32.05a75.33 75.33 0 00-10.36 1.4m31.83-1.9S52.06 10.97 42.53.67C33 10.97 32.06 31.55 32.06 31.55zm11 1.92c.54-.64 6.44-8 4.51-16.75-9.15-2-16.82 4.56-16.82 4.56l12.17 12.17a75.56 75.56 0 00-10.37-1.4v20.93s20.59-.93 30.88-10.46c-5.43-5-13.74-7.67-20.37-9"
          className="prefix__H"
        />
        <path
          d="M63.94 51.55L51.68 63.81s7.68 6.58 16.82 4.56c2.02-9.12-4.56-16.82-4.56-16.82zm-31.88 1.9S33 74.04 42.53 84.33c9.53-10.29 10.46-30.88 10.46-30.88zm-11-1.9s-6.57 7.68-4.56 16.82c9.15 2 16.82-4.56 16.82-4.56zm.1-18.12l12.16-12.16s-7.67-6.58-16.82-4.56c-1.92 8.73 4 16.11 4.51 16.75-6.63 1.37-14.93 4-20.37 9 10.3 9.53 30.88 10.46 30.88 10.46V32.05a75.33 75.33 0 00-10.31 1.4zm31.83-1.88S52.06 10.97 42.53.67C33 10.97 32.06 31.55 32.06 31.55zm11 1.92c.54-.64 6.44-8 4.51-16.75-9.15-2-16.82 4.56-16.82 4.56l12.17 12.17a75.56 75.56 0 00-10.37-1.4v20.93s20.59-.93 30.88-10.46c-5.43-5.04-13.74-7.67-20.37-9.04z"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M42.53 66.03a23.52 23.52 0 0016.617-40.165A23.52 23.52 0 0019.01 42.55c.006 12.986 10.534 23.51 23.52 23.51"
          className="prefix__H"
        />
        <path
          d="M42.53 66.03a23.52 23.52 0 0016.617-40.165A23.52 23.52 0 0019.01 42.55c.022 12.974 10.546 23.48 23.52 23.48z"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M32.06 31.55S33 10.97 42.53.67c9.53 10.3 10.46 30.88 10.46 30.88"
          className="prefix__H"
        />
        <path
          d="M32.06 31.55S33 10.97 42.53.67c9.53 10.3 10.46 30.88 10.46 30.88"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M52.99 53.47s-.93 20.59-10.46 30.88C33 74.06 32.06 53.47 32.06 53.47"
          className="prefix__H"
        />
        <path
          d="M52.99 53.47s-.93 20.59-10.46 30.88C33 74.06 32.06 53.47 32.06 53.47"
          className="prefix__E prefix__F prefix__G"
        />
        <use xlinkHref="#prefix__B" className="prefix__H" />
        <use xlinkHref="#prefix__B" className="prefix__E prefix__F prefix__G" />
        <use xlinkHref="#prefix__C" className="prefix__H" />
        <use xlinkHref="#prefix__C" className="prefix__E prefix__F prefix__G" />
        <use xlinkHref="#prefix__D" className="prefix__H" />
        <use xlinkHref="#prefix__D" className="prefix__E prefix__F prefix__G" />
        <use xlinkHref="#prefix__E" className="prefix__H" />
        <use xlinkHref="#prefix__E" className="prefix__E prefix__F prefix__G" />
        <path
          d="M53.48 32.05s20.59.94 30.88 10.47c-10.29 9.53-30.88 10.46-30.88 10.46"
          className="prefix__H"
        />
        <path
          d="M53.48 32.05s20.59.94 30.88 10.47c-10.29 9.53-30.88 10.46-30.88 10.46"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M31.57 52.98S10.99 52.05.69 42.52c10.3-9.53 30.88-10.47 30.88-10.47"
          className="prefix__H"
        />
        <path
          d="M31.57 52.98S10.99 52.05.69 42.52c10.3-9.53 30.88-10.47 30.88-10.47"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M51.68 21.29s7.68-6.58 16.82-4.56c2 9.15-4.56 16.82-4.56 16.82"
          className="prefix__H"
        />
        <path
          d="M51.68 21.29s7.68-6.58 16.82-4.56c2 9.15-4.56 16.82-4.56 16.82"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M51.68 63.83s7.68 6.58 16.82 4.57c2-9.15-4.56-16.82-4.56-16.82"
          className="prefix__H"
        />
        <path
          d="M51.68 63.83s7.68 6.58 16.82 4.57c2-9.15-4.56-16.82-4.56-16.82"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M21.11 33.55s-6.57-7.68-4.56-16.82c9.15-2 16.82 4.56 16.82 4.56"
          className="prefix__H"
        />
        <path
          d="M21.11 33.55s-6.57-7.68-4.56-16.82c9.15-2 16.82 4.56 16.82 4.56"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M21.11 51.55s-6.57 7.68-4.56 16.82c9.15 2 16.82-4.56 16.82-4.56"
          className="prefix__H"
        />
        <path
          d="M21.11 51.55s-6.57 7.68-4.56 16.82c9.15 2 16.82-4.56 16.82-4.56"
          className="prefix__E prefix__F prefix__G"
        />
        <path
          d="M42.53 47.41a4.85 4.85 0 100-9.7 4.85 4.85 0 00-3.429 8.279 4.85 4.85 0 003.429 1.421"
          className="prefix__H"
        />
        <path
          d="M42.53 47.41a4.85 4.85 0 100-9.7 4.85 4.85 0 00-3.429 8.279 4.85 4.85 0 003.429 1.421z"
          className="prefix__E prefix__F prefix__G"
        />
      </g>
      <path
        d="M42.52 7.01v19.73m0 31.57v19.73M7.01 42.52h19.73m31.57 0h19.73m-10.4-25.11L53.69 31.36M31.36 53.68L17.41 67.63m0-50.22l13.95 13.95m22.33 22.32l13.95 13.95"
        className="prefix__E prefix__F prefix__G"
      />
    </svg>
  )
}
