import React from "react";
import { Link } from "gatsby"
import "./index.scss";

export const LangMenu = ({ languages }) => {
  const currentLocationUrl = window.location.pathname;
  const pathSegments = currentLocationUrl.split("/").filter(param => param !== '');
  const urlWithoutLang = pathSegments.length === 1 ? '' : pathSegments[pathSegments.length - 1];

  return (
    <div className={"m-langmenu__container"}>
      { languages.map(lang => {
        return (
          <Link key={lang}
                to={`/${lang}/${urlWithoutLang ? urlWithoutLang: ''}`}
                className={"m-langmenu__lang-item"}
          >
            <span>{lang}</span>
          </Link>
        )}
      )}
    </div>
  )
}
